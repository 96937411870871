import React from 'react';

export class FormField extends React.Component {

  render() {
    const {id, label, name, required, error, placeholder, fieldName, ...other} = this.props;
    const display = error ? 'block' : 'none';

    return (
        <div className={`form-group gmpayment-${fieldName}-field`}>
          <label htmlFor={id} className={required ? 'required' : ''}
                 id={`${id}-label`}>{label}</label>
          <div className="form-input">
            <input type="text"
                   className="form-control"
                   id={id}
                   name={name}
                   required={required}
                   placeholder={placeholder}
                   {...other}/>
            <div className="invalid-feedback" id={`${id}-error`}
                 style={{display: display}}>
              {error}
            </div>
          </div>
        </div>
    );
  }
}