import React from 'react';

import {UtilitiesService} from 'shared';
import {PaymentOption} from '../container/PaymentOption';

const KLARNA_URL = 'https://x.klarnacdn.net/kp/lib/v1/api.js';

export class KlarnaPaymentOptions extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      display: true,
      categoryDisplay: {},
    };
  }

  componentDidMount() {
    this.initializeKlarna(this.props.session);
  }

  initializeKlarna(session) {
    const {client_token, payment_method_categories} = session;
    let me = this;

    if (client_token && payment_method_categories) {
      UtilitiesService.loadJS(KLARNA_URL);

      window.klarnaAsyncCallback = function() {
        // This is where you start calling Klarna's JS SDK functions

        let initError = false;
        try {
          Klarna.Payments.init({
            client_token: client_token,
          });
          me.setState({
            display: true,
          });
        } catch (e) {
          console.error(e);
          initError = true;
          payment_method_categories.forEach(cat => {
            me.props.onInitError(cat.identifier)();
          });
        }

        if (!initError) {
          payment_method_categories.forEach(cat => {
            const {identifier} = cat;

            try {
              Klarna.Payments.load({
                container: `#klarna_container_${identifier}`,
                payment_method_categories: [identifier],
                instance_id: identifier,
              }, function(res) {
                console.debug(res);

                //hide option if show_form is false
                if (res.show_form) {
                  me.props.onRegisterSubmitHandler(identifier,
                      () => me.handleSubmit(identifier));
                } else {
                  me.props.onInitError(identifier)();
                }
                me.setState(prevState => {
                  let categoryDisplay = {...prevState.categoryDisplay};
                  categoryDisplay[identifier] = res.show_form;
                  return {
                    categoryDisplay: categoryDisplay,
                  };
                });

              });
            } catch (e) {
              console.error(e);
              me.props.onInitError(identifier)();
            }
          });
        }

      };
    }
  }

  handleSubmit(paymentCategory) {
    const {billingAddress} = this.props;
    return new Promise((resolve, reject) => {
      let updateData = null;
      if (billingAddress){
        updateData = {billing_address: billingAddress}
      }
      try {
        Klarna.Payments.authorize({
          payment_method_categories: [paymentCategory],
          instance_id: paymentCategory,
        }, updateData, (response) => {
          let {authorization_token, show_form, approved, finalize_required, error} = response;
          if (approved && authorization_token) {
            resolve(response);
          } else {
            reject(response);
          }
        });
      } catch (e) {
        console.error('exception', e);
        reject(e);
      }
    });
  }

  getTranslation(id, type, defaultText){
    const {getTranslationsForId} = this.props;
    if (getTranslationsForId){
      let o =  getTranslationsForId(id);
      if (o){
        return o[type] || defaultText;
      }
    }
    return defaultText;
  }

  renderCategory(c, numberPaymentOptions) {
    const {categoryDisplay} = this.state;
    const checked = c.identifier === this.props.selectedPaymentOption;
    const showOption = categoryDisplay[c.identifier];
    const showRadio = numberPaymentOptions > 1;
    const display = checked || (!showRadio && showOption) ? 'block' : 'none';

    return (
        <React.Fragment key={c.identifier}>
          {showOption && showRadio &&
          <PaymentOption id={`klarna-${c.identifier}`}
                         value={c.identifier}
                         label={this.getTranslation(c.identifier, "label", c.name)}
                         subtext={this.getTranslation(c.identifier, "subtext","")}
                         checked={checked}
                         onChange={this.props.onChange}
                         imgSrc={c.asset_urls.descriptive}/>}
          <div id={`klarna_container_${c.identifier}`}
               style={{display: display}}></div>
        </React.Fragment>
    );
  }

  render() {
    const {session, numberPaymentOptions} = this.props;
    let categories = [];
    if (session) {
      categories = this.props.session.payment_method_categories;
    }

    if (this.state.display && categories) {
      return (
          <div>
            {categories.map(c => this.renderCategory(c, numberPaymentOptions))}
          </div>
      );
    } else {
      return null;
    }
  }
}